<template >
  <div class="plans py-2 mb-5 ">
    <div class="row">
      <div class="col-12 text-center">
        <h5 class="font-weight-bold text-muted">Conoce nuestros planes para ti</h5>
      </div>
      <div class="col-12 text-center">
        <p class="lead">Escoja el que mejor se adapte a tus necesidades</p>
      </div>

  </div>

      <div class="row justify-content-center">
        <div class="col-md-5 col-12 mt-4 pt-2" >
            <div class="card pricing-rates business-rate shadow bg-light rounded text-center border-0">
              <!---->
              <div class="card-body py-5">
                  <h6 class="title font-weight-bold text-uppercase text-primary mb-4"> Plan Básico </h6>
                  <div class="d-flex justify-content-center mb-4">
                    <span class="price h3 mb-0">{{formatterAmount((totalNoCat/12).toFixed(2))}}</span>
                    <span class="h6 align-self-end mb-1 pl-1"> MXN/mes</span>
                   

                    </div>

                

                    
                    <ul class="list-unstyled mb-0 pl-0">
                      <li class="h6 text-muted mb-0"  v-for="(f, index) in noCatFeatures"  v-bind:key="index" v-show="index < 7" >
                        <span class="text-primary h5 mr-2">
                          <!-- <i class="uil uil-check-circle align-middle"></i> -->
                          </span>  {{f.seccion}} - {{f.feature}}  
                      </li>


                    
                    </ul>
                    <br>
                   
                    <a href="javascript:void(0)" @click="handleShowModal('NoCat')"><b>Ver cobertura +</b></a> <br>
                    <a href="javascript:void(0)" class="btn btn-primary mt-4" @click="next(totalNoCat, 'NoCAT')" >Elegir plan</a>
                    
              </div>
            </div>
        </div>
        <div class="col-md-5 col-12 mt-4 pt-2" v-if="isZoneCastratophic">
            <div class="card pricing-rates business-rate shadow bg-light rounded text-center border-0"
            style="height: 33.2rem; margin-bottom: 1rem;">
              <div class="ribbon ribbon-right ribbon-warning overflow-hidden"><span class="text-center d-block shadow small h6"> Catastrófico</span></div>
              <div class="card-body py-5">
                  <h6 class="title font-weight-bold text-uppercase text-primary mb-4"> Plan Amplio</h6>
                  <div class="d-flex justify-content-center mb-4">
                    <span class="price h3 mb-0">{{formatterAmount((totalCat/12).toFixed(2))}}</span>
                    <span class="h6 align-self-end mb-1 pl-1"> MXN/mes</span> </div>
                    
                    <ul class="list-unstyled mb-0 pl-0">

                  

                      <li class="h6 text-muted mb-0">
                        <!-- <span class="text-primary h5 mr-2">
                          <i class="uil uil-check-circle align-middle"></i>
                          </span> -->
                          <span style="font-size: 20px">Incluye plan básico </span>
                      </li> 

                       <!-- <li class="h6 text-muted mb-0"  v-for="(f, index) in features"  v-bind:key="index" v-show="index < 7" >
                        <span class="text-primary h5 mr-2">
                        
                          </span>  {{f.seccion}} - {{f.feature}}  
                      </li> -->
                      
                      <li class="h6 text-muted mb-0">
                        <span class="text-primary h5 mr-2">
                          <i class="uil uil-check-circle align-middle"></i>
                          </span>Terromoto  y/o erupción volcánica
                      </li>
                      <li class="h6 text-muted mb-0">
                        <span class="text-primary h5 mr-2">
                          <i class="uil uil-check-circle align-middle"></i>
                          </span>Riesgos hidrometeorológicos (Daños por lluvias)
                      </li>

                      <br>
                   
                    <a href="javascript:void(0)" @click="handleShowModal('CAT')"><b>Ver cobertura +</b></a> <br>

                      
                    
                    </ul>
                    <br>
                   
                    <!-- <a href="javascript:void(0)" @click="visible = true"><b>Ver cobertura +</b></a> <br> -->
                   
                    <a href="javascript:void(0)" class="btn btn-primary mt-4" @click="next(totalCat, 'CAT')" >Elegir plan</a>
              </div>
            </div>
        </div>
        
       
    </div>
    <div style="text-align:right">
      <a href="javascript:void(0)" class="btn btn-primary mt-6" @click="getPDF()" >Descargar Cotización</a>

    </div>
   

           <a-modal v-model="visible" title="Coberturas" :mask="true" :width="700" >

               <template slot="footer">
        <a-button class="btn btn-primary" key="back" @click="handleCancel">
          Entendido
        </a-button>
       
      </template>


     
           
            <div class="container-fluid mt-1 mb-5 table-responsive">
                <table class="table table-bordered table-striped">
                  <tbody>
                      <div v-if="cat == 'CAT'" class="font-weight-light">
                        <tr>
                            <th scope="column">
                            <span class="font-weight-light" style="font-size: 20px">Descripción de Cobertura</span> 
                            </th> 
                            <td >
                                <div class=" text-center"> 
                                <b>Monto Asegurado <br>($ MXN)</b> 
                                </div> 
                            </td>
                        </tr>
                       
                          <tr  v-for="(f, index) in features"  v-bind:key="index">
                            <th scope="row"><span class="d-block">{{f.seccion}}  -  {{f.feature}}</span>  </th> 
                            <td > <div class="text-center check" > {{f.amount == 'Amparada' ? '' : '$'}} {{f.amount.replace('.00', '')}}  </div> </td>
                          </tr>
                        </div>

                        <div v-else>
                          <tr>
                            <th scope="column">
                            <span class="font-weight-light" style="font-size: 20px">Descripción de Cobertura</span> 
                            </th> 
                            <td >
                                <div class=" text-center"> 
                                <b>Monto Asegurado <br>($ MXN)</b> 
                                </div> 
                            </td>
                        </tr>
                          <tr v-for="(f, index) in noCatFeatures"  v-bind:key="index">
                            <th scope="row"><span class="d-block">{{f.seccion}}  -  {{f.feature}}</span>  </th> 
                            <td > <div class="text-center check" > {{f.amount == 'Amparada' ? '' : '$'}} {{f.amount.replace('.00', '')}}  </div> </td>
                          </tr>
                        </div>
                      
                    </tbody>
                </table>

            </div>

           </a-modal>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { Carousel, Slide } from "vue-carousel";
import { getQuotePDF, convertToDollar } from "@/services/costco.service";
export default {
  expose: ['getPlan'],
  data(){
    return{
      // plans: []
      plan:{},
      costs: [0,0,0],
      costsZone1: 0, 
      costsZone2: 0,
      costsZone3: 0,
      isZoneCastratophic: false,
      totalCat: 0,
      totalNoCat: 0,
      visible: false,
      isAlfa1: false,
      features: [],
      planData: {},
      catFeatures: [],
      noCatFeatures: [],
      cat: "NoCat",
      isDownloading: false,

    }
  },
  props: [
    'zipCode',
    'package'
  ],

  created(){
    
  },
  async mounted(){
    this.getZoneCost()
    this.getPlan()
  },

  components: {
    Carousel,
    Slide,
  },
  computed: {
    ...mapGetters({
      plans: "plans/ordered",
    }),
    query() {
      return this.$route.query;
    },
  },
  methods: {
    emitEvent(){
      this.$emit("getPlan");
    },
    handleCancel(){
      this.visible = false
    },

    handleShowModal(plan){
      try {
        const handleAccents = (text) => {
          if (text == "Huracan") {
            return "Huracán";
          } else if (text == "Terremoto y/o erupcion volcanica"){
            return "Terremoto y/o erupción volcánica";
          } else if (text == "Remocion de escombros"){
            return "Remoción de escombros";
          } else if (text == "Gastos medicos resultantes de un accidente"){
            return "Gastos médicos resultantes de un accidente";
          } else if (text == "Equipo electronico y/o electrodomestico"){
            return "Equipo electrónico y/o electrodoméstico";
          } else if (text == "Articulos en transito"){
            return "Artículos en tránsito";
          } else if (text == "R.c. sin comprobacion via judicial"){
            return "Responsabilidad civil sin comprobación via judicial";
          } else if (text == "Perdida y/o robo de llaves"){
            return "Pérdida y/o robo de llaves";
          } else if (text == "Perdida de rentas"){
            return "Pérdida de rentas";
          } else if (text == "Perdida y/o robo de documentos"){
            return "Pérdida y/o robo de documentos";
          } else {
            return text
          }
        }

        this.planData = JSON.parse(localStorage.getItem('planData') || null)

        if(plan=="CAT") {
        const featureCat = this.planData.CAT
        this.cat = "CAT"
        this.features = featureCat.features.filter(item => item.seccion != null).map((item, idx) => {
        //  const itemCat = featuresCat.features[idx]
        const str = item.feature.toLowerCase()
        const strSeccion = item.seccion.toLowerCase()
        const cob = str.charAt(0).toUpperCase() + str.slice(1);
        const sec = strSeccion.charAt(0).toUpperCase() + strSeccion.slice(1);
        item.feature = handleAccents(cob)
        item.seccion =  handleAccents(sec)
        return {
          ...item
         }
        })
        this.visible=true
      }
      if(plan=="NoCat") {
        const featuresNoCat = this.planData.NoCAT
        this.cat = "NoCat"
        this.noCatFeatures = featuresNoCat.features.filter(item => item.visible == true).map((item, idx) => {
        //  const itemCat = featuresCat.features[idx]
        const str = item.feature.toLowerCase()
        const strSeccion = item.seccion.toLowerCase()
        const cob = str.charAt(0).toUpperCase() + str.slice(1);
        const sec = strSeccion.charAt(0).toUpperCase() + strSeccion.slice(1);
        item.feature = handleAccents(cob);
        item.seccion = handleAccents(sec);
        return {
          ...item
        }   
      })
        this.visible=true
      }
      

        
      } catch (a) {
        
      }
      
      


    },

    next(amount, cat){
      const monthAmount = Number((amount/12).toFixed(2))
      const selectPlan = {amount, cat, monthAmount}
      localStorage.setItem('p',JSON.stringify(selectPlan))

      this.$gtag.event('plan_selected_'+cat, {
            'event_category': 'quote',
            'event_label': 'Plan selected',
            'value': 1
          })

      this.$emit('go')
    },



     formatterAmount(value){
      return `$ ${new Intl.NumberFormat("es-MX", { currency: "MXN",  maximumFractionDigits: 10 }).format(value)}`
      // return `${value}%`;
    },

    async getZoneCost(){
      try {
        const dataZone = localStorage.getItem('zones') || null
        const zones = JSON.parse(dataZone)
        let zona_hidro = zones[0].zones.zona_hidro
        if(zona_hidro <= 4){
           this.isAlfa1 = true
        }
        this.isZoneCastratophic = zones[0].isZoneCastratophic
      } catch (error) {
        console.log(error);
      }
  
    },

    async getPlan(){
      try {

         this.planData = JSON.parse(localStorage.getItem('planData') || null)
         const featuresNoCat = this.planData.NoCAT
         const featuresCat = this.planData.CAT

         this.totalCat = featuresCat.total
         this.totalNoCat = featuresNoCat.total
       
         this.noCatFeatures = featuresNoCat.features.filter(item => item.visible == true).map((item, idx) => {
           const str = item.feature.toLowerCase()
           const strSeccion = item.seccion.toLowerCase()
           item.feature = str.charAt(0).toUpperCase() + str.slice(1);

           item.seccion = strSeccion.charAt(0).toUpperCase() + strSeccion.slice(1);
           
           return {
            ...item
           }
         })

        await this.calculateRateConvetion()
  
      } catch (a) {
        console.log(a);
      }
    },

    async calculateRateConvetion() {
      const rateValue = await convertToDollar();
      const planData = JSON.parse(localStorage.getItem("planData"))
      const premiumCat = planData.CAT.total;
      const premiumNoCat = planData.NoCAT.total;
      const convertionValueCat = premiumCat/rateValue;
      const convertionValueNoCat = premiumNoCat/rateValue;
      localStorage.setItem("ratesValues", JSON.stringify({ convertionValueCat, convertionValueNoCat}));
    },
   
    getPriceCost(feature, idx){
      if(feature.costs){
        return this.formatterAmount(feature.costs[idx])
      }

      if(feature.values){
        return feature.values[idx]
      }

      return  '-'
    },

    printFilter() {
      // console.log(this.filter);
    },

    getBillingFrequencyAbbr(frequency) {
      switch (frequency) {
        case "daily":
          return "day";
        case "weekly":
          return "week";
        case "monthly":
          return "month";
        case "yearly":
          return "year";
      }
    },

    async getPDF() {
      const plans = JSON.parse(localStorage.getItem('planData'))
      const zones = JSON.parse(localStorage.getItem('zones'))
 
        this.isDownloading = true
        getQuotePDF(plans.quoteId,this.query.postalCode,zones[0].isZoneCastratophic)
          .then((response) => {
              var fileURL = window.URL.createObjectURL(
              new Blob([response.data], {
                type: `application/pdf`,
              })
            )
            var fileLink = document.createElement('a')
            fileLink.href = fileURL
            fileLink.setAttribute('download', 'Cotizacion.pdf')
            document.body.appendChild(fileLink)
            fileLink.click()
          })
          .catch(console.error)
          .finally(() => (this.isDownloading = false))
      }
  },
};
</script>

<style lang="scss">
.VueCarousel-wrapper {
  padding-bottom: 1rem;
}


.check i {
    margin-top: 10px;
    margin-bottom: 10px;
    width: 38px;
    height: 38px;
    border-radius: 50%;
    // background-color: #b9f6ca82;
    line-height: 38px;
    font-size: 27px;
    color: #2674b4;
    font-weight: 300
}
</style>